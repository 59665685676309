import React from 'react'

import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import SliderRotator from '../organisms/SliderRotator'
import Section from '../organisms/Section'
import useWidth from '../../hooks/useWidth'
// import SectionCards from '../molecules/SectionCards'

function Login({
  autologin,
  hash
}) {
  const { width } = useWidth()

  return (
    <div className='mc-page login slow_ani'>

      <div className='mc-login__container' style={{"backgroundImage": `url(/assets/images/login-bg.png)`}}>
        <div className='mc-login__header'>
          <Container fullHeight={true}>
            <VerticalCenter>
              <img src={`/assets/images/login_banner.png`} />
            </VerticalCenter>
          </Container>
        </div>
        <Container>
          <LoginForm
            title="Gane un bono por alcanzar sus metas usando su <strong>Tarjeta de Crédito Davivienda Mastercard.</strong>"
            type="credentials"
            fields={[
              {field: "document",label: "Ingrese los últimos 5 dígitos de su documento",placeholder: "12345",maxlength: 5, separated:false},
              {field: "birthdate",label: "Fecha de nacimiento",placeholder: "dd/mm/aaaa"},
              {field: "terms",label: "He leído y acepto los",termsText: "términos",termsLink: "/terminos-y-condiciones"}
            ]}
            buttonText="Ingresar"
            background="#fff"
            autologin={autologin}
            hash={hash}
          />
        </Container>

        {width > 992 &&
          <img src={`/assets/images/banner-login.png`} />
        }
      </div> 

      
        
      <Section title="¿Cómo ganar?">
        <SliderRotator
        slidesToScroll={1}
          dots
          arrows
          mobileItemsToShow={1}
          desktopItemsToShow={3}
          className={'login-slider'}
          list={
            [
              {
                id: 1,
                image: `slider_1.jpeg`,
                title: "Use su Tarjeta",
                desc: "Haga sus compras en comercio físicos y digitales como un maestro."
              },
              {
                id: 2,
                image: `slider_2.jpeg`,
                title: "Logre sus metas",
                desc: "Consulte cuál es el monto y cantidad de transacciones que debe cumplir."
              },
              {
                id: 3,
                image: `slider_3.jpeg`,
                title: "Descargue, redima y disfrute",
                desc: "Al alcanzar su meta podrá descargar el bono que prefiera y después redimirlo."
              },
              {
                id: 4,
                image: `slider_4.jpg`,
                title: "Siga participando",
                desc: "Conozca su nueva meta y logre redimir hasta 3 bonos en su comercio favorito."
              }
            ]
          }
        />
      </Section>

      {/* <SectionCards /> */}

    </div>
  )
}
export default Login