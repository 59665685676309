import React from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import GoalCounter from '../atoms/GoalCounter'
import Button from '../atoms/Button'

const SectionPremioMayor = () => {
  return (
    <div className='SectionPremioMayor'>
      <Container>
        <Columns desktop={2} mobile={1}>
          <Col>
            <div className="content showMobile">
              <h3>¡Llévese el premio mayor!</h3>
              <p>Su Tarjeta de Crédito Davivienda Mastercard es la clave para participar por este increíble recompensa.</p>              
            </div>
            <div className="img_premioMayor">
              <img src="/assets/images/img_premioMayor.png" alt="" />
            </div>
          </Col>
          <Col>
            <div className="content hide-mobile">
              <h3>¡Llévese el premio mayor!</h3>
              <p id='parrafo1'>Su Tarjeta de Crédito Davivienda Mastercard es la clave para participar por este increíble recompensa.</p>
              <p>Cuantas más veces alcance las metas, estará más cerca de redimir este <strong>{`<premio>`}.</strong></p>
              <div className="goalCounterProgress">
                <GoalCounter label='Ha cumplido su meta' />
              </div>
              <div className="btn-section">
                <Button
                  type='primary'
                  text={'Quiero el premio mayor'}

                />
              </div>
            </div>
          </Col>
        </Columns>
      </Container>
    </div>
  )
}

export default SectionPremioMayor
