import React, { useEffect, useContext, useState } from 'react'
import { StateController } from './../../App'

import CustomModal from '../molecules/CustomModal'
import ProgressBar from '../atoms/ProgressBar'
import Container from '../../layouts/Container/Container'
import Icon from '../atoms/Icon'
import Button from '../atoms/Button'
import useWidth from '../../hooks/useWidth'
import Benefits from '../organisms/Benefits'
import Preloader from '../atoms/Preloader'

import { getPrice, getProgressPercent, sendDataLayer } from '../../utils/functions'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import SectionPremioMayor from '../molecules/SectionPremioMayor'
import BannerInfo from '../molecules/BannerInfo'

function Progress() {
  const {context} = useContext(StateController)
  const [showPushModal, setShowPushModal] = useState(false)
  const { width } = useWidth()

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })

    if (!localStorage.getItem('pushModal')) {
      // setShowPushModal(true)
    }
  }, [])

  const pushModalToggler = () => {
    setShowPushModal(false)
    localStorage.setItem('pushModal', true)
  }

  const handleProgress = async () => {
    sendDataLayer({datalayer: {event: 'progress',text: "Redimir premio", link: "/premios"},repeat: true})
  }

  return (
    <div className='mc-page progress mc-page-progress slow_ani'
    style={{"backgroundImage": `url(/assets/images/login-bg${width > 992 ? '-desktop' : ''}.png)`}}
    >

      <CustomModal
        title="Aprovecha la prima y llega más rápido a tu meta."
        image="push_modal_1.png"
        desc="¡Gana bonos en tus comercios favoritos!"
        cta="Cerrar"
        icon={false}
        push={true}
        show={showPushModal} setShowModal={pushModalToggler}
      />

      {width > 0 &&
        <div 
          className='mc-page-content' 
          // style={{"backgroundImage": `url(/assets/images/login-bg${width > 992 ? '-desktop' : ''}.png)`}}
        >
          <Container>
            <div className='mc-page-content__master'>
              <div className='mc-page-content__inner'>
                <VerticalCenter>
                  <h1>Su dedicación diaria, lo hará todo un maestro de las compras</h1>
                  <div className='mc-page-content__separator'>
                    <img src="/assets/images/separator.png" alt="Titulos" />
                  </div>
                  <p>Para redimir el bono que desea, alcance su meta en monto y cantidad de transacciones.</p>
                </VerticalCenter>
              </div>
              <img src={`/assets/images/progress_banner_${width < 992 ? 'mobile' : 'desktop'}.png`} alt="Hombre computador" />
            </div>

            {context
              ? <div className='mc-page-content__info'>
                  <h2>{context.user.nickname},</h2>
                  <p>¿Llegó a la meta? Redima su bono antes del 15 de agosto de 2024.</p>

                  <div className='mc-page-content__barInfo'>
                    <div className='mc-page-content__barInfo--head'>
                      <Icon name="shop" /> Compras
                    </div>
                    <div className='mc-page-content__barInfo--row'>
                      <span>
                        <strong>Lleva:</strong> {getPrice(context.tracing ? context.tracing[`amount_${context.block}`] : 0)}
                      </span>
                      <span>
                        <strong>Meta:</strong> {getPrice(context.user ? context.user[`goal_amount_${context.block}`] : 0)}
                      </span>
                    </div>
                    <ProgressBar
                      percent={getProgressPercent(context, 'amount')}
                      label={`${getProgressPercent(context, 'amount')}%`}
                    />
                  </div>

                  <div className='mc-page-content__barInfo'>
                    <div className='mc-page-content__barInfo--head'>
                      <Icon name="trxs" /> Transacciones
                    </div>
                    <div className='mc-page-content__barInfo--row'>
                      <span>
                        <strong>Lleva:</strong> {context.tracing ? context.tracing[`trx_${context.block}`] : 0}
                      </span>
                      <span>
                        <strong>Meta:</strong> {context.user ? context.user[`goal_trx_${context.block}`] : 0}
                      </span>
                    </div>
                    <ProgressBar
                      percent={getProgressPercent(context, 'trx')}
                      label={`${getProgressPercent(context, 'trx')}%`}
                    />
                  </div>

                  <div className='mc-page-content__dateupdate'>
                  *Última actualización: {context.tracing && context?.tracing?.date_update}
                  </div>

                  <div onClick={handleProgress} className='mc-page-content__ctas'>
                    <Button
                      type="primary"
                      text="Redimir premio"
                      loading={false}
                      status="active"
                      link="/premios"
                    />
                  </div>
                </div>
              : <Preloader
                  type="section"
                  invert
                />
            }
          
          </Container>

        </div>

      }

      <SectionPremioMayor />

      <BannerInfo />

      <Container>
      <Benefits
              cta="Descubrir"
              link="/alianzas"
            />
      </Container>

    </div>
  )
}
export default Progress