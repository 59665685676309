import React from 'react'
import SliderRotator from '../organisms/SliderRotator'
import Button from '../atoms/Button'

const SectionAssistanceServices = () => {
    return (
        <div className='SectionAssistanceServices'>
            <h2>Conozca las asistencias Davivienda</h2>
            <div className="slider-allies">
                <SliderRotator
                    slidesToScroll={1}
                    dots
                    arrows
                    mobileItemsToShow={1}
                    desktopItemsToShow={2}
                    className={'login-slider'}
                    list={
                        [
                            {
                                id: 1,
                                image: `assis-1.png`,
                                title: "¿Qué son?",
                                desc: "Nuestras asistencias son servicios gratuitos que le brindan sus Tarjetas de Crédito Davivienda Mastercard en alianza con Servicios Bolívar."
                            },
                            {
                                id: 2,
                                image: `assis-2.png`,
                                title: "¿Qué incluyen?",
                                desc: "Nuestras asistencias le brindan un paquete anual con 5 servicios diferentes que le facilitan la vida cuando lo necesite."
                            },
                            {
                                id: 3,
                                image: `assis-3.png`,
                                title: "Beneficios para usted",
                                desc: "Aproveche los servicios para clientes: Clásico, Jóvenes, Premium y Persona natural."
                            },
                        ]
                    }
                />
            </div>
            <div className='btn-allies-assistance'>
                <Button
                    type='primary'
                    text='Más información'
                    link={'/'}
                    status={'active'}                    
                />
            </div>
        </div>
    )
}

export default SectionAssistanceServices
