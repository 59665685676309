import React from 'react'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Container from '../../layouts/Container/Container'
import useWidth from '../../hooks/useWidth'

function CardBox({
  image,
  imageDesktop,
  title,
  desc,
  type,
  className,
}) {
  const { width } = useWidth()

  return (
    <div className={`mc-cardbox-container ${type ? type : 'normal'} ${className ? className : ''}`}>
      {imageDesktop
        ? <img src={`/assets/images/${width < 992 ? image : imageDesktop}`} alt="Step image" />
        : image &&
            <img src={`/assets/images/${image}`} alt="Step image" />
      }
      {type 
        ? <div className='mc-cardbox-container--fullContainer'>
            <Container fullHeight>
              <VerticalCenter>
                {title &&
                  <h2>{title}</h2>
                }
                {desc &&
                  <div dangerouslySetInnerHTML={{ __html: desc }} />
                }
              </VerticalCenter>
            </Container>
          </div>
        : <>
            {title &&
              <h2>{title}</h2>
            }
            {desc &&
              <div className='desc-paraTodos'  dangerouslySetInnerHTML={{ __html: desc }} />
            }
          </>
      }

    </div>
  )
}

export default CardBox