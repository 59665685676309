import React from 'react'
import Container from '../../layouts/Container/Container'

const BannerInfo = () => {
    return (
        <Container>
            <div className="BannerInfo">
                <div className="img_info">
                    <img src="/assets/images/img_info.png" alt="" />
                </div>
                <div className="content">
                    <h3>¡Su esfuerzo será recompensado con lo que más disfruta!</h3>
                    <p>Desde que redime su premio favorito, comienza a participar. Recuerde que puede redimir hasta 3 veces su premio.</p>

                </div>
            </div>
        </Container>
    )
}

export default BannerInfo
