import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import AlliancesGallery from '../organisms/AlliancesGallery'
import Preloader from '../atoms/Preloader'
import useWidth from '../../hooks/useWidth'
// import Benefits from '../organisms/Benefits'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import SectionAssistanceServices from '../molecules/SectionAssistanceServices'

function Alliances() {
  const [alliances, setAlliances] = useState(false)
  const {context} = useContext(StateController)
  const { width } = useWidth()

  useEffect(() => {
    if (context && context.alliances) {

      const formater = context.alliances.filter((item) => {
        if (item.activate) {
          if (item.activate.includes('/')) {
            const timer = new Date(item.activate)
            const today = new Date()
            if (today.toDateString() == timer.toDateString()) return true
            
          } else if (item.activate !== '') {
            const today = new Date()
            const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
            const todayDay = days[today.getDay()]
            const enableDays = item.activate.split(',')
            let returner = false

            for (let i = 0; i < enableDays.length; i++) {
              if (enableDays[i].toLowerCase() == todayDay.toLocaleLowerCase()) returner = true
            }
            return returner
          }
        } else {
          return true
        }
      }).map((item) => {
        
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule}`,
          image: item.image,
          termsData: item.terms,
          link: item.link
        }
      })
      
      setAlliances(formater)
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page alliances mc-page-alliances slow_ani'>

      {width > 0 &&
        <div 
          className='mc-page-content' 
          style={{"backgroundImage": `url(/assets/images/login-bg${width > 992 ? '-desktop' : ''}.png)`}}
        >
          <Container>
            <div className='mc-page-content__master'>

              <div className='mc-page-content__inner'>
                <VerticalCenter>
                <h1>Explore los beneficios que traen compras maestras</h1>
                <div className='mc-page-content__separator'>
                  <img src="/assets/images/separator.png" alt="Titulos" />
                </div>
                <p>Con su Tarjeta de Crédito Davivienda Mastercard, acceda a grandes oportunidades en comercios aliados y disfrute de asistencias exclusivas.</p>
                </VerticalCenter>
              </div>
              <img src={`/assets/images/alliances_banner_${width < 992 ? 'mobile' : 'desktop'}.png`} alt="Mujer tarjeta celular" />
            </div>


            {alliances
              ? <AlliancesGallery
                  alliedBoxType={2}
                  itemsPerLine={3}
                  limit={false}
                  termsOnModal={true}
                  limitButtonText="Ver todos los aliados"
                  list={alliances}
                  termText="Aplican términos y condiciones."
                  termLink="/#"
                />
              : <Preloader
                  type="section"
                  invert
                />
            }

            <SectionAssistanceServices />

            {/* <Benefits
              cta="Descubrir"
              link="/alianzas"
              event="alliances"
            /> */}
          </Container>
        </div>
      }


    </div>
  )
}
export default Alliances