import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import {
  useLocation 
} from 'react-router-dom'

import { StateController } from './../../App'

import VerticalCenter from '../VerticalCenter/VerticalCenter'
import Container from '../Container/Container'
import Icon from '../../components/atoms/Icon'
import Profile from '../../components/atoms/Profile'
import Preloader from '../../components/atoms/Preloader'
import { sendDataLayer, getProgressPercent } from '../../utils/functions'

function Header({
  headerType,
  logo,
  items,
  submenu,
  loggedIn,
  logOutAction
}) {
  const { context } = useContext(StateController)
  const location = useLocation()
  const [showOthers, setShowOthers] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const isSubLevel = (children) => {
    const page = location.pathname.replace('/','')
    const data = children.find((item) => item.page === page)

    return data ? true : false
  }

  const togglerMenu = (e) => {
    
    setShowOthers(!showOthers)

    

    if (e.target.closest('.mc-header-container__nav--item')) {
      let text = e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span').innerText
      let link = 'none'

      sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})
    }

    if (e.target.closest('a')) {
      let text = e.target.closest('a').querySelector('span').innerText
      let link = e.target.closest('a').getAttribute('href')
      sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})
    }
  
  }

  const closeSumMenu = () => {
    setShowMenu(false)
  }

  const addDataLayer = (e) => {
    if (e.target.closest('.mc-header-container__nav--item')) {
      let text = e.target.closest('.mc-header-container__nav--item').querySelector('a .mc-app-centry > span').innerText
      let link = e.target.closest('.mc-header-container__nav--item').querySelector('a').getAttribute('href')
      sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})
    }
  }

  useEffect(() => {
    const child = items.find((item) => item.sublevel)

    if (child) {
      setHasChildren(child)
    }
  }, [items])

  useEffect(() => {

    window.addEventListener('click', ({ target }) => {
      const window = target.closest('.mc-header-container__nav--extra')
      const button = target.closest('.mc-header-container__nav--button')

      if (window === null && button === null) setShowOthers(false)
    })
  }, [])

  const closeSession = () => {
    logOutAction()
    setShowOthers(false)
    sendDataLayer({datalayer: {event: 'menu',text: 'Cerrar sesión', link: '/'},repeat: true})
  }

  return (
    <div 
      className={`
      mc-header-container mc-header-container-type-${headerType} 
      mc-header-container-page-${location.pathname.replace('/','') !== '' ? location.pathname.replace('/','') ? location.pathname.replace('/','') : 'login' : 'login' } 
      ${showMenu ? 'show-menu' : ''}`
    }>
      <div className='mc-header-container__menu'>

        <a className="mc-header-container__logo--desktop" href="/">
          <img src={`/assets/images/${logo}`} alt="Logo Banco Davivienda" />
        </a>

        <div className="mc-header-container__vector">
          <img src={`/assets/images/vector.png`} alt="Banco Davivienda S.A" />
        </div>

        <div className="mc-header-container__summary">
          {context
            ? <VerticalCenter>
                <div className='mc-header-container__summary--item amount'><Icon name="shop" /> {getProgressPercent(context, 'amount')}%</div>
                <div className='mc-header-container__summary--item trx'><Icon name="trxs" /> {getProgressPercent(context, 'trx')}%</div>
                <Link to={`/progreso`}>Ver más</Link>
              </VerticalCenter>
            : <VerticalCenter>
                <Preloader
                  type="section"
                  invert
                />
              </VerticalCenter>

          }
          
        </div>

        <Container fullHeight={true}>
          <a className="mc-header-container__logo" href="/">
            <VerticalCenter>
              <img src={`/assets/images/${logo}`} alt="Logo Banco Davivienda" />
            </VerticalCenter>
          </a>

          <nav className="mc-header-container__nav">
            {headerType === 4 &&
              <div
                className="mc-header-container__mobile--overlay" 
                onClick={closeSumMenu}
              >
              </div>
            }
            {headerType === 4 &&
              <div className="mc-header-container__nav--close" onClick={closeSumMenu}>
                <Icon name="close" />
              </div>
            }

            {loggedIn
              ? <ul>
                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return true
                    } else {
                      return false
                    }
                  }).map((item, index) => {
                    return (
                      item.sublevel
                        ? <li 
                            className={`mc-header-container__nav--item slow_ani ${item.page} ${ ((showOthers) || (isSubLevel(item.children))) ? 'mc-header-container__nav--item-active' : ''}`}
                            onClick={togglerMenu}
                            key={index}
                          >
                            <div className="mc-header-container__nav--button">
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title} 
                                </span>
                              </VerticalCenter>
                            </div>
                          </li>
                        : <li 
                            className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                            onClick={addDataLayer}
                            key={index}
                          >
                            <Link to={`/${item.page}`}>
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title}
                                </span>
                              </VerticalCenter>
                            </Link>
                          </li>
                    )
                  })}
                </ul>
              : <ul>

                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return false
                    } else {
                      return true
                    }
                  }).map((item, index) => {
                    return (
                      <li 
                        className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                        onClick={addDataLayer}
                        key={index}
                      >
                        <Link to={`/${item.page}`}>
                          <VerticalCenter>
                            <span>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              {item.title}
                            </span>
                          </VerticalCenter>
                        </Link>
                      </li>
                    )
                  })}

                  {location.pathname !== '/' &&
                    <li className={`mc-header-container__nav--item-active slow_ani`}>
                      <Link to="/" className='mc-header-link'>
                        <span>Ingresar</span>
                      </Link>
                    </li>
                  }
                </ul>
            }

            {hasChildren &&
              <div className={`mc-header-container__nav--extra slow_ani ${showOthers ? 'opened' : ''}`}>
                {hasChildren.children.map((item, index) => {
                  return (
                    <Link to={`/${item.page}`} key={index} onClick={togglerMenu}>
                      <span>
                        {item.title}
                        {item.icon &&
                          <Icon name={item.icon} />
                        }
                      </span>
                    </Link>
                  )
                })}
                <div className="mc-header-container__nav--close" onClick={closeSession}>
                  Cerrar sesión <Icon name={'close-session'} />
                </div>
              </div>
            }
          </nav>
        </Container>
      </div>

      {submenu &&
        <div className={`mc-header-container__submenu ${!loggedIn ? 'not-login' : ''}`}>

          
          <Container fullHeight={true}>
            <VerticalCenter>
              {context
                ? submenu.type === 'nickname' && loggedIn && context?.user?.nickname &&
                    <Profile
                      nickname={context.user.nickname}
                      defaultLetter={submenu.default}
                      context={context}
                    />
                : <Preloader
                  type="section"
                  invert
                />
              }
            </VerticalCenter>
          </Container>
        </div>
      }
    </div>
  )
}

export default Header