import React, { useRef, useState } from 'react'
import Slider from "react-slick"

import CardBox from '../molecules/CardBox'
import Icon from '../atoms/Icon';

function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  customSliderStep,
  className,
  singleButton,
  arrows,
  dots,
  slidesToScroll
}) {
  const sliderRef = useRef(null);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const settings = {
    dots: dots ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: slidesToScroll ? slidesToScroll : false,
    autoplay: false,
    arrows: arrows ? true : false,
    centerPadding: '20px',
    afterChange: (index) => {      
      const lastSlide = sliderRef.current.props.children.length - 1;
      setIsLastSlide(index+1 === lastSlide);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  }  
  const handleNext = () => {    
    if (isLastSlide) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext(); 
    }
  };

  return (

    <>
    <div className={`mc-slider-rotator size-${list.length} step-${customSliderStep}`}>
      <Slider ref={sliderRef} {...settings}>
        {list.map((slide, index) => {

          return (
            <div key={index} className='mc-slider-rotator__slide'>
              <CardBox
                image={slide.image}
                imageDesktop={slide.imageDesktop}
                title={slide.title}
                desc={slide.desc}
                type={slide.type}
                className={className}
              />
            </div>
          )
        })}
      </Slider>
    </div>
    {
        singleButton &&
        <button
          className={`single-button ${isLastSlide ? 'rotate' : ''}`}
          onClick={handleNext}
        >
          <Icon name='arrow-slider' />
        </button>

      }
    
    </>
  )
}

export default SliderRotator